$(document).ready(function() {
    /*Filter jquery code*/
    //select code 
    //$('.refine-result-info ul li a').click(function () {
    //$(document).on("click", ".filterlink, .filterSublink", function () {
    $(document).on("click", ".filterlink, .filterSublink", function () {
        //alert("5");
        //var dataType = $(this).attr('data-type');
        //var dataVal = $(this).attr('datatitle');
        //var dataId = $(this).attr('id');
        //var displayMode = "s";
        //if ($(this).attr('data-display')) {
        //    displayMode = $(this).attr('data-display');
        //}
        //if (displayMode == "s") {
        //    $('.' + dataType).each(function () {
        //        var dataCounttemp = $(this).attr('data-count-theme');
        //        $('.by-' + dataType + ' ul  a[id="' + dataCounttemp + '"]').parent().show();
        //        $(this).parent().remove();
        //        //console.log(dataType);
        //        if (dataType == "category") {
        //            var dataType1 = "subcategory";
        //            $('.' + dataType1).each(function () {
        //                //var dataCounttemp = $(this).attr('data-count-theme');
        //                $(this).parent().remove();
        //            });
        //            $('.by-subcategory').find('ul li').remove();
        //            $('.by-subcategory').hide();

        //        }
        //    });
        //}
        
        //$('.selectedFilterVal ul').append('<li>' + dataType + ' : ' + dataVal + '<span class="crossBtn ' + dataType + '" data-count-theme="' + dataId + '" data-type="' + dataType + '"></span></li>');
        //$(this).parent().hide();
        //check();
       
    });    

	//deselect code
	 $(document).on('click', '.selectedFilterVal > ul > li > span', function(){
		var dataCount = $(this) .attr('data-count-theme');
		var dataType = $(this) .attr('data-type');

		var dataCounttemp = $(this).attr('data-count-theme');
		$('.by-' + dataType + ' ul  a[id="' + dataCounttemp + '"]').parent().show();
		$(this).parent().remove();
        
		if (dataType == "category") {
		    dataType = "subcategory";
		    $('.' + dataType).each(function () {
		        var dataCounttemp = $(this).attr('data-count-theme');
		        $(this).parent().remove();
		    });
		    $('.by-subcategory').find('ul li').remove();
		    $('.by-subcategory').hide();

		}
       
		if (dataType == "category" || dataType == "subcategory") {
		    dataType = "subsubcategory";
		    $('.' + dataType).each(function () {
		        var dataCounttemp = $(this).attr('data-count-theme');
		        $(this).parent().remove();
		    });
		    $('.by-subsubcategory').find('ul li').remove();
		    $('.by-subsubcategory').hide();
		}
	     
		check();
	 });
    


	 //Clear all selected filter
	 $('.clear-all-filter') .click(function(){
		 $('.selectedFilterVal > ul > li') .remove();
		 $('.refine-result-info ul > li') .show();
		 $('.related-source') .show();
		 $('.refine-result-info').show();
		 check();
	 });	 
	 //category show hide
	 $('.by-Category ul li a') .click(function(){
		$('.by-Category').hide();
		 parCheck();
	 });
	 $(document).on('click', 'span[data-type="Category"]', function(){
		 $('.by-Category').show();
	 });	 
	 //Region show hide
	 $('.by-Region ul li a') .click(function(){
	     var numItem = $('.by-Region ul li:visible').length;
		 numItem==0 ? $('.by-Region') .hide() : $('.by-Region') .show();
		 parCheck();
	 });
	 $(document).on('click', 'span[data-type="Region"]', function(){
		 $('.by-Region') .show();
	 });	 
	 //year show hide
	 $('.by-Year ul li a') .click(function(){
	     var numItem = $('.by-Year ul li:visible').length;
		 numItem==0 ? $('.by-Year') .hide() : $('.by-Year') .show();
		 parCheck();
	 });
	 $(document).on('click', 'span[data-type="Year"]', function(){
		 $('.by-Year') .show();
	 });
	 
	 
	function check(){
	    var numItem = $('.selectedFilterVal ul li').length;
		numItem==0 ? $('.selection-area') .hide() : $('.selection-area') .show();
	}	
	function parCheck(){
		$('.refine-result-info').is(":visible") ? $('.related-source') .show() : $('.related-source') .hide();
	}
});